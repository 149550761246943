import React from "react";

const ServiceCard = ({ service }) => {
  return (
    <div className="cards">
      <div className="insidetab">
        {service.image}
        <div className="DetailServices">
          <div>
            <label>{service.title}</label>
          </div>
          <div>{service.description}</div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
