import React from "react";
import "./Sub.css";

export default function Subsribe() {
  return (
    <div className="sbc">
      <div className="container" align="center">
        <div className="row">
          <div className="col">Looking For The Best IT Business Solution</div>
          <div className="col">
            <input placeholder="ENTER YOUR EMAIL ADDRESS"/>
            <button>SUBSCRIBE</button>
          </div>
        </div>
      </div>
    </div>
  );
}
