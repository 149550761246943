import React from "react";

// Service components
import ServiceCard from "./ServiceCard";
import "./Services.css";
const SoftwareServices = () => {
  const services = [
    {
      id: 1,
      title: "Web Development",
      description: "Custom web development for your business needs",
      image: <ion-icon name="laptop-outline"></ion-icon>,
    },
    {
      id: 2,
      title: "Mobile Development",
      description: "Native and hybrid mobile app development",
      image: <ion-icon name="phone-portrait-outline"></ion-icon>,
    },
    {
      id: 3,
      title: "UI/UX Design",
      description: "Design and development of user-friendly interfaces",
      image: <ion-icon name="logo-designernews"></ion-icon>,
    },
    {
      id: 4,
      title: "Cloud Solutions",
      description: "Scalable and reliable cloud solutions for your business",
      image: <ion-icon name="snow-outline"></ion-icon>,
    },
    {
      id: 5,
      title: "Blockchain Development",
      description: "Blockchain technology development and consulting",
      image: <ion-icon name="snow-outline"></ion-icon>,
    },
    {
      id: 6,
      title: "Artificial Intelligence",
      description: "AI-powered solutions to optimize business operations",
      image: <ion-icon name="bookmarks-outline"></ion-icon>,
    },
    {
      id: 7,
      title: "Data Analytics",
      description: "Data analytics and visualization for actionable insights",
      image: <ion-icon name="cube-outline"></ion-icon>,
    },
    {
      id: 8,
      title: "Quality Assurance",
      description: "Comprehensive testing to ensure software quality",
      image: <ion-icon name="male-outline"></ion-icon>,
    },
  ];

  return (
    <div id="services">
      <div className="container">
        <div className="service-cards" align="center">
          <h2>services we provide</h2>
          <br />
          <br />
          <p>We Provide You With High Quality IT Solution Services</p>
          <br />
          <br />
          <br />
          <div className=" ">
            {services.map((service) => (
              <ServiceCard key={service.id} service={service} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareServices;
