import axios from "axios";

// export let USER_API_URL = "http://localhost/yitaconnect";
// export let USER_API_URL = "/api";
export let USER_API_URL = "/Api";
class Api {
  sendmail(user) {
    return axios.post(`${USER_API_URL}/mail.php`, user);
  }
  sendmailp(user) {
    return axios.post(`${USER_API_URL}/mailp.php`, user);
  }
}
export default new Api();
