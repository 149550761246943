import React from "react";
import "./Footer.css";
import Logo from "../../img/logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <div className="footer">
        <div className="flowhatsap" align="right">
          <a
            className="footerdd"
            href="https://api.whatsapp.com/send?phone=971565843743"
            method="get"
            target="_blank"
          >
            <img
              className="floatwhatsapp"
              src="https://www.freepnglogos.com/uploads/whatsapp-logo-png-transparent-33.png"
            />
          </a>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-5">
              {/* <img src={Logo} width="50%" /> */}
              <h2>
                Lets develop
                <br /> software together!
              </h2>
              <p>
                Be the first to know about
                <br />
                IT innovations and interesting case studies.
              </p>

              <div>
                <div>
                  <input />
                  <button>SUBSCRIBE</button>
                </div>
                <br />
                <p className="agree">
                  By signing up you agree to our Terms of Use and privacy <br />
                  policy, including the use of cookies and transfer of your
                  personal information.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="Footer_Tab">
                <div className="row">
                  <div className="col">
                    <p>
                      <a href="/">Home</a>
                    </p>
                    <p>
                      <a href="#services">service</a>
                    </p>
                    <p>
                      <a href="#about">about us</a>
                    </p>
                    <p>
                      <a href="#about">contact us</a>
                    </p>
                  </div>
                  <div className="col">
                    <p>website development</p>
                    <p>website hosting</p>
                    <p>system development</p>
                    <p>graphic design</p>
                  </div>
                  <div className="col">
                    <p>
                      <Link to="Quote">Get a quote</Link>
                    </p>
                    <p>email us</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="ft">
            <div className="row">
              <div className="col-3">SpeedExpress tech Rights.</div>
              <div className="col color_grey">
                © 2023 SpeedExpress technologies. All Rights Reserved. Privacy
                Policy.
              </div>
              <div className="col" align="right">
                <a href=" " target="_blank">
                  <i className="ti-facebook"></i>
                </a>
                <a href=" " target="_blank">
                  <i className="ti-twitter"></i>
                </a>
                <a href=" " target="_blank">
                  <i className="ti-github"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
