import React, { useState } from "react";
import "./Quote.css";
import Api from "../../Api/Api";
import axios from "axios";

export default function Quote() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [data, setData] = useState("");
  const [send, setSend] = useState(0);
  async function createAppoint() {
    setSend(1);
    let datax = {
      name: name,
      from: email,
      data: data,
      subject: "WE HAVE RECIEVED YOUR QUOTE",
      msg: "We have recieved your quote below. ",
      thank: "Thank for contacting speedExpress Technology.",
    };

    await Api.sendmail(datax).then((res) => {
      console.log(res.data);

      if (res.data == "success") {
        window.alert("Mail sent Successfully");
      } else {
        window.alert("Mail Failed");
      }
    });
    setSend(0);
    window.location.reload();
  }
  return (
    <div>
      <div className="container">
        <div className=" ">
          {/* <div className="col flimg">
            <img src="https://blog.pitsco.com/hs-fs/hubfs/Blog%20Images/Coding-computer-girl-1366-1118.png?width=1366&name=Coding-computer-girl-1366-1118.png" />
          </div> */}

          <div className=" ">
            <div className="quotepage" align="center">
              <h4>GET A FREE QUOTE</h4>
              <p>
                Send your requirements for a website, we will research your
                specification and <br />
                competitors, create a prototype of the website for free and sed
                a result to you!
              </p>
              <div>
                <label>Name</label>
                <br />
                <input onChange={(e) => setName(e.target.value)} value={name} />
              </div>
              <div>
                <label> Email</label>
                <br />
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div>
                <label> Contact Number</label>
                <br />
                <input
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                />
              </div>
              <div>
                <label>Services</label>
                <br />
                <textarea
                  onChange={(e) => setData(e.target.value)}
                  placeholder="SEND US A MESSAGES"
                />
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    createAppoint();
                  }}
                  style={send == 0 ? { opacity: 1 } : { opacity: 0.3 }}
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
