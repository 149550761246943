import React from "react";
import "./Partner.css";

export default function Partner() {
  return (
    <div>
      <div className="partner">
        <h2>Our clients</h2>
        <div className="partnermain">
          <img src="https://smartbox-brokerservices.com/static/media/Icon.3d7307bfd6b5b86f17be.png" />
          <img src="http://mileshr.ae/wp-content/uploads/2023/02/logo.1057fa5b246a1ffb71e5.png" />
          <img src="https://zrahr.com/static/media/logo_white.1743a0adbae0d9f4b691.png" />
        </div>
      </div>
    </div>
  );
}
