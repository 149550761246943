import React from "react";
import "./Quotemain.css";

export default function Quotemain() {
  return (
    <div>
      <section className="quoteslider">
        <div className="container">
          <div className="content">
            {/* <h1>Innovative Software Solutions for Your Business</h1> */}
            <br />
          </div>
        </div>
      </section>
    </div>
  );
}
